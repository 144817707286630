import './Centered.scss'

function Centered (props) {
  return <div className="grid place-items-center" style={{ ...(props.style || {}) }}>
    <div className={props.className}>
      {props.children}
    </div>
  </div>
}

export default Centered