import { useEffect, useRef } from "react"
import './ContentBlock.scss'

function ContentBlock (props) {
  const content = useRef(null)
  const bg1 = useRef(null)
  const bg2 = useRef(null)

  const isVideoBackground = props.background?.split('.')?.pop() === 'mp4'

  useEffect(() => {
    function onResize () {
      const bodyBounds = document.body.getBoundingClientRect()
      const bounds = content.current.getBoundingClientRect()
      const bgs = [bg1.current, bg2.current]
      bgs.filter(bg => !!bg).forEach(bg => {
        bg.style.height = `${bounds.height}px`
        bg.style.top = `${bounds.top - bodyBounds.top}px`
      })
    }
    
    const interval = setInterval(() => onResize(), 100)

    window.addEventListener('resize', onResize)
    window.addEventListener('deviceorientation', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('deviceorientation', onResize)
      clearInterval(interval)
    }
  }, [])

  return <>
    <div ref={content} className={`${props.fade ? 'fade-block' : ''} ${props.pad ? 'pad-block' : ''} content-block grid place-item-center`}>
      <div className="z-10 relative">
        {props.children}
      </div>
    </div>

    { isVideoBackground ? 
      <>
        <video ref={bg1} className={`${props.fade ? 'fade' : ''} content-block-bg`} muted playsInline autoPlay loop>
          <source src={props.background} type="video/mp4"/> 
        </video>
        <div ref={bg2} className={`${props.fade ? 'fade' : ''} video-overlay absolute top-0 left-0 w-full h-full`}/>
      </> :
        <div ref={bg1} className={`${props.fade ? 'fade' : ''} content-block-bg`} style={{
          backgroundImage: props.background.indexOf('.') >= 0 ? `url(${props.background})` : '',
          backgroundColor: `${props.background}`,
          backgroundPosition: `50% ${(props.offset || 0.5) * 100}%`
        }}/>
    }
  </>
}

export default ContentBlock