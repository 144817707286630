import ContentBlock from './components/ContentBlock'
import Centered from './components/Centered'
import { SocialIcon } from 'react-social-icons'
import ImageGallery from 'react-image-gallery'
import { useState } from 'react'
import './App.scss'

const genoCache = new Set()
const bgCache = new Set()

const genokinBackgrounds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const discordImages = [...new Array(6)].map((_, i) => {
  return {
    original: `/discord/${i + 1}.png`,
    thumbnail: `/discord/${i + 1}.png`,
    originalWidth: 256,
    originalHeight: 256,
    thumbnailWidth: 256,
    thumbnailHeight: 256
  }
})

function App () {
  const [genokin, setGenokin] = useState(Math.floor(Math.random() * 1000))
  const [bg, setBg] = useState(4)
  const [loading, setLoading] = useState(false)

  function generate () {
    if (!loading) {
      const newGenokin = Math.floor(Math.random() * 1000)
      const newBg = (bg + 1) % genokinBackgrounds.length
      
      if (!bgCache.has(newBg)) {
        const image = new Image()
        image.src = `/${(newBg % genokinBackgrounds.length) + 1}.png`
        bgCache.add(newBg)
      }

      if (!genoCache.has(newGenokin)) {
        const image = new Image()
        image.src = `/genokin/${newGenokin}.png`
        genoCache.add(newGenokin)
        setLoading(true)
        image.addEventListener('load', () => {
          setTimeout(() => {
            setGenokin(newGenokin)
            setBg(newBg)
            setLoading(false)
          }, 100)
        })
      } else {
        setGenokin(newGenokin)
      }
    }
  }

  return (
    <div className="app">
      <ContentBlock background="hero.mp4" bottomEffectColor="rgba(255, 20, 0, 1)">
        <Centered style={{ padding: '5vh 0' }}>
          <div className="circle">
            <Centered>
              <h1 className="heading">Genosphere</h1>
              <p>Ten thousand Genokin live and interact with each other in the Genosphere. These genomic mashups are all distinctly unique creations—ancestors of the fifty perfect metacreatures known as the Genos.</p>
              <div className="read-more hidden md:block" onClick={() => document.body.querySelector('.promo-content').scrollIntoView({ behavior: 'smooth' })}>
                <h2>read more</h2>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 330 330" xmlSpace="preserve">
                  <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                </svg>
              </div>
            </Centered>
          </div>
          <div className="text-center p-4 space-x-5">
            <SocialIcon target="_blank" url="https://discord.gg/rQPxR9x73r" bgColor="#eee" fgColor="rgba(0, 0, 0, 0.35)"/>
            <SocialIcon target="_blank" url="https://twitter.com/GenosphereNFT" bgColor="#eee" fgColor="rgba(0, 0, 0, 0.35)"/>
          </div>
        </Centered>
      </ContentBlock>

      <ContentBlock background="1.svg" fade={true} offset={0} topEffectColor="rgba(255, 80, 0, 1)">
        <Centered className="w-full max-w-screen-lg p-10 pb-20">
          <div className="promo-content relative grid grid-cols-1 lg:grid-cols-2 w-full gap-5">
            <div className="leading-7 bg-black bg-opacity-90 px-10 py-8 rounded-lg col-span-1 lg:col-span-2 lg:text-justify">
              <div className="relative grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div className="m-auto">
                  <h1 className="text-4xl">Project</h1>
                  <p className="lg:text-left">Genosphere is an NFT project set to release on the Ethereum mainnet. Those who participate in our minting phase will have full ownership of Genokin in the Genosphere, including the ability to list their creatures for sale on OpenSea. We recommend following our <a href="https://discord.gg/rQPxR9x73r" target="_blank" rel="noreferrer">Discord</a> and <a href="https://twitter.com/GenosphereNFT" target="_blank" rel="noreferrer">Twitter</a> for updates.</p>
                </div>
                <div className="text-center">
                  <div className="m-auto relative" style={{ maxWidth: 256, maxHeight: 256 }}>
                    <img className="absolute top-0 left-0 w-full h-full rounded-md" src={`/${(bg % genokinBackgrounds.length) + 1}.png`} data-loading={loading} alt=""/>
                    <img className="relative z-10" src={`/genokin/${genokin}.png`} style={{filter:`drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.5))`}} data-loading={loading} alt=""/>
                  </div>
                  <button className="generate-button px-5 py-2 mt-4 w-full rounded" data-load={loading} onClick={generate}>Generate!</button>
                </div>
              </div>
            </div>
            <div className="leading-7 bg-black bg-opacity-90 px-10 py-8 rounded-lg lg:text-justify">
              <h1 className="text-4xl">World</h1>
              <p>The Genosphere world exists as a persistent, always-online website where onlookers can view the everyday life of the Genokin within it. These Genokin will travel through the world and engage in battles with each other in what we’re calling a “zero-player game.” Each Genokin will have its own unique, one-of-one avatar and battle statistics, both viewable on OpenSea.</p>
            </div>
            <div className="leading-7 bg-black bg-opacity-90 px-10 py-8 rounded-lg lg:text-justify">
              <h1 className="text-4xl">Mint</h1>
              <p>Details on the minting phase of this project will be decided after the team is able to gauge the level of interest. Although this project is currently in a releasable state, variables such as the total number of NFTs available, price per NFT minted, and NFTs mintable per transaction have yet to be determined.</p>
            </div>
          </div>
          {/* <div className="text-center p-5 space-x-5">
            <SocialIcon url="https://discord.gg/rQPxR9x73r" bgColor="#eee" fgColor="rgba(0, 0, 0, 0.35)"/>
            <SocialIcon url="https://twitter.com/genosphere" bgColor="#eee" fgColor="rgba(0, 0, 0, 0.35)"/>
          </div> */}
        </Centered>
      </ContentBlock>

      <ContentBlock background="rgb(29, 78, 216)" offset={0.75} fade={true}>
        <Centered className="w-full max-w-screen-lg pt-10 pb-72 px-20">
          <h1 className="text-4xl">Join our community!</h1>
          <p>The best way to stay up to date is to follow us where we're most active. We'll be posting regular updates on Twitter and in Discord. Check in regularly and you can be one of the few eligible for our upcoming pre-sale!</p>
          <div className="links mt-8">
            <a className="social-btn text-center w-full lg:w-auto" href="https://discord.gg/rQPxR9x73r" target="_blank" rel="noreferrer">Discord</a>
            <a className="social-btn text-center w-full mt-4 lg:w-auto lg:mt-0 lg:ml-4" href="https://twitter.com/GenosphereNFT" target="_blank" rel="noreferrer">Twitter</a>
          </div>
        </Centered>
      </ContentBlock>

      <ContentBlock background="3.svg" offset={0.6} fade={true}>
        <Centered className="w-full max-w-screen-lg p-10 pb-24 -mt-44">
          <div className="leading-7 bg-black bg-opacity-90 px-10 py-8 rounded-lg col-span-1 lg:col-span-2 lg:text-justify">
            <div className="relative grid grid-cols-1 lg:grid-cols-2 w-full gap-5">
              <div className="m-auto">
                <h1 className="text-4xl">Future</h1>
                <p className="lg:text-left">Contrary to other NFT projects, Genosphere is taking a development-first approach. Once the minting phase has been completed, all Genokin will be released into the wild—there’s no long waiting period or far-out roadmap promises.<br/><br/>Genosphere NFT holders will also have priority access and perks in a free-to-play Discord bot game, which has been under development for several months and will eventually be released to the public.</p>
              </div>
              <div className="m-auto w-full h-full rounded-xl overflow-hidden" style={{background:'#36393f'}}>
                <ImageGallery
                  showThumbnails={false}
                  showNav={false}
                  showPlayButton={false}
                  showFullscreenButton={false}
                  autoPlay={true}
                  onImageLoad={() => window.dispatchEvent(new Event('resize')) }
                  items={discordImages}
                />
              </div>
            </div>
          </div>
          {/* <div className="text-center p-5 space-x-5">
            <SocialIcon url="https://discord.gg/rQPxR9x73r" bgColor="#eee" fgColor="rgba(0, 0, 0, 0.35)"/>
            <SocialIcon url="https://twitter.com/genosphere" bgColor="#eee" fgColor="rgba(0, 0, 0, 0.35)"/>
          </div> */}
        </Centered>
      </ContentBlock>

      <ContentBlock background="rgb(6, 5, 9)" offset={0.75} fade={true}>
        <Centered className="w-full max-w-screen-lg pt-10 pb-10 px-20">
          <h1 className="text-4xl text-center">Team</h1>
          <div className="flex justify-center align-middle flex-wrap">
            <TeamMember name="Craig" job="Founder" color="#66bb6a" socials={{twitter: 'c89'}}/>
            <TeamMember name="Andy" job="Artist" color="#ef5350" socials={{twitter: 'AndyCarolan'}}/>
            <TeamMember name="Victor" job="Marketing" color="#ffee58"/>
            <TeamMember name="Bryan" job="Lead Developer" color="#29b6f6"/>
            <TeamMember name="James" job="Bot Developer" color="#7e57c2" socials={{twitter: 'Jargon64'}}/>
          </div>
        </Centered>
        <Centered className="w-full max-w-screen-lg pb-8 px-20 text-center">
          <p>Genosphere NFT</p>
        </Centered>
      </ContentBlock>
    </div>
  )
}

function TeamMember (props) {
  const shadow = []
  const smallShadow = []

  const thickness = 5
  const smallThickness = 3

  const iterations = 32

  for (let i = 0; i < iterations; i++) {
    shadow.push(`${Math.cos(i / iterations * Math.PI * 2) * thickness}px ${Math.sin(i / iterations * Math.PI * 2) * thickness}px 0px #263238`)
    smallShadow.push(`${Math.cos(i / iterations * Math.PI * 2) * smallThickness}px ${Math.sin(i / iterations * Math.PI * 2) * smallThickness}px 0px #263238`)
  }
  return <div className="team-member grid place-items-center" style={{ backgroundImage: `url('/team/${props.name}.svg')` }}>
    <div className="team-member-label">
      <div style={{textShadow: shadow.join(',')}}>{props.name.toLowerCase()}</div>
      <div style={{textShadow: smallShadow.join(','), color: props.color}}>{props.job.toLowerCase()}</div>
    </div>
    <div className="team-member-label">
      <div style={{textShadow: shadow.join(',')}}>{props.name.toLowerCase()}</div>
      <div style={{textShadow: smallShadow.join(','), color: props.color}}>{props.job.toLowerCase()}</div>
    </div>
    {props.socials && <div className="team-member-socials">
      { props.socials.twitter &&
        <a href={`https://twitter.com/${props.socials.twitter}`} target="_blank" rel="noreferrer">
          <svg version="1.1" id="Logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 248 204" xmlSpace="preserve">
          <g id="Logo_1_">
            <path id="white_background" class="st0" d="M221.95,51.29c0.15,2.17,0.15,4.34,0.15,6.53c0,66.73-50.8,143.69-143.69,143.69v-0.04
              C50.97,201.51,24.1,193.65,1,178.83c3.99,0.48,8,0.72,12.02,0.73c22.74,0.02,44.83-7.61,62.72-21.66
              c-21.61-0.41-40.56-14.5-47.18-35.07c7.57,1.46,15.37,1.16,22.8-0.87C27.8,117.2,10.85,96.5,10.85,72.46c0-0.22,0-0.43,0-0.64
              c7.02,3.91,14.88,6.08,22.92,6.32C11.58,63.31,4.74,33.79,18.14,10.71c25.64,31.55,63.47,50.73,104.08,52.76
              c-4.07-17.54,1.49-35.92,14.61-48.25c20.34-19.12,52.33-18.14,71.45,2.19c11.31-2.23,22.15-6.38,32.07-12.26
              c-3.77,11.69-11.66,21.62-22.2,27.93c10.01-1.18,19.79-3.86,29-7.95C240.37,35.29,231.83,44.14,221.95,51.29z"/>
          </g>
          </svg>
        </a>
      }
    </div>}
  </div>
}

export default App
